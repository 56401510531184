/**
 * @function preloadImages
 * @param {Array} srcs pass the array for the caching the image
 * @description this will take all the images and cache them into the browser
 */
function preloadImages(srcs) {
    if (!preloadImages.cache) {
        preloadImages.cache = [];
    }
    var img;
    for (var i = 0; i < srcs.length; i++) {
        img = new Image();
        img.src = srcs[i];
        preloadImages.cache.push(img);
    }
}

// then to call it, you would use this
var imageSrcs = [
    "./dist/images/journey-entrance-thumb.png", 
    "./dist/images/journey-parking-thumb.png", 
    "./dist/images/journey-mainlobby-thumb.png", 
    "./dist/images/journey-food-court-thumb.png",
    "./dist/images/journey-denistry-thumb.png",
    "./dist/images/journey-primary-care-thumb.png",
    "./dist/images/journey-all-other-thumb.png",
    "./dist/images/journey-v-infodesk-thumb.jpg"
];

// preloading images cache
preloadImages(imageSrcs);



/**
 * @var home
 * @description this is main vue app variable for the holding the app
 */
var home = new Vue({
    el: '#home',
    data: {
        lang: 'en',
        langData: {},
        infoPopOpen: false,
        startWalkPosition: {
            lat: 42.3377907,
            long: -71.1050197
        },
        slideMenuActive: false,
        appointmentCardOpen: false,
        loadingCmplt: false,
        landScapeWarning: false
    },
    /**
     * @description vue mounteed lifecycle hook
     */
    mounted(){
        // check the current browser
        this.browserChecking();
        // check the current language
        this.langChecking();
        // get the lang from local storage and set to in the state
        this.lang = localStorage.getItem('bch_app_lang');
        // as per current language dynamic language data fetching
        if(this.lang == 'en'){
            this.langData = this.fetchStaticcontI18('en');
        }
        if(this.lang == 'es'){
            this.langData = this.fetchStaticcontI18('es');
        }
        // if(this.lang == 'ja'){
        //     this.langData = jaLangData;
        // }

        /**
         * @description $nextTick is vue api that will continues check the state change.
         *              we used for the check the window drag and resize height and with change detaction.
         */
        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowWidth);
            window.addEventListener('resize', this.getWindowHeight);
        
            //Init
            this.getWindowWidth()
            this.getWindowHeight()
        });
    },
    /**
     * @description this where vue holds all the methods
     */
    methods: {
        /**
         * @func getWindowWidth
         * @description this will detact the window width when the screen resize
         */
        getWindowWidth(event) {
            // this.windowWidth = document.documentElement.clientWidth;
        },
        /**
         * @func getWindowHeight
         * @description this will detact the window height when the screen resize
         */
        getWindowHeight(event) {
            // this.windowHeight = document.documentElement.clientHeight;
            let el = document.getElementsByClassName('menu-items-wrap')[0];
            if(document.documentElement.clientHeight < 500){
                el.style.height = `${document.documentElement.clientHeight - 50}px`;
                this.landScapeWarning = true;
            } else {
                el.style.height = `auto`;
                this.landScapeWarning = false;
            }
        },
        /**
         * @func fetchStaticcontI18
         * @description this will fetch all the data as per language selected.
         */        
        fetchStaticcontI18: function(lang){
            // **************************************
            // vue and axios scoping problem resolved
            // **************************************
            var that = this;
            axios.get(`./i18/${lang}.json`)
                .then(function(response){
                    if(response.status == 200){
                        // debugger;
                        that.langData = response.data.welcome;

                        // remove loading
                        that.loadingCmplt = true;
                    }
                });
        },
        /**
         * @func browserChecking
         * @description this will check the browser, if Chrome or FireFox: hide the warning
         */ 
        browserChecking: function(){
var winNav = window.navigator;
            var isIOSChrome = winNav.userAgent.match("CriOS");
            var isGoogleChrome = winNav.vendor.match("Google");
            var isFireFox = winNav.userAgent.toLowerCase().indexOf('firefox') > -1;

//alert( "IOS Chrome " + isIOSChrome );
//alert( "Chrome " + isGoogleChrome );
//alert( "FireFox " + isFireFox );

            if ( isIOSChrome || isGoogleChrome || isFireFox ) {
                $(".browserWarning").addClass("hide");
            } else {
                $(".appMainLoader").addClass("hide");
            }
        },
        /**
         * @func langChecking
         * @description this will check the language as per URL query param and local storage data
         */ 
        langChecking: function(){
            if(localStorage.getItem('bch_app_lang') == null){
                localStorage.setItem('bch_app_lang', 'en');
                if (history.pushState) {
                    // var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?view=en';
                    // window.history.pushState({path:newurl},'',newurl);
                }
            } else {
                if (history.pushState) {
                    // var lang = localStorage.getItem('bch_app_lang');
                    // var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?view=' + lang;
                    // window.history.pushState({path:newurl},'',newurl);
                }
            }
        },
        /**
         * @func langChange
         * @param $event pass the language CODE by this function
         * @description change the language value as changing the select box
         */
        langChange: function ($event) {
            localStorage.setItem('bch_app_lang', $event.target.value);
            location.reload();
        },

        /**
         * @func openInfoPop
         * @description this will open the info popup
         */
        openInfoPop: function(){
            this.infoPopOpen = true;
        },
        /**
         * @func closeInfoPop
         * @description this will close the info popup
         */
        closeInfoPop: function(){
            this.infoPopOpen = false;
        },
        /**
         * @func openSlideMenu
         * @description this will open the slide menu.
         */
        openSlideMenu: function(){
            this.slideMenuActive = true;
            // sound playing
            // let sound = document.getElementById("menuSlide");
            // sound.play();
        },
        /**
         * @func closeSlideMenu
         * @description this will close the slide menu.
         */
        closeSlideMenu: function(){
            this.slideMenuActive = false;
            // sound playing
            // let sound = document.getElementById("hotspotSound");
            // sound.play();
            // setTimeout(() => {
            //     sound.pause();
            //     sound.currentTime = 0;
            // }, 800);
        },
        /**
         * @func appointmentCardOpenTrigg
         * @description this will slide up the appointment card from the footer.
         */
        appointmentCardOpenTrigg: function(){
            this.appointmentCardOpen = true;
        },
        /**
         * @func appointmentCardOpenTriggCls
         * @description this will slide down and hide the appointment card from the footer.
         */
        appointmentCardOpenTriggCls: function(){
            this.appointmentCardOpen = false;
        },
        /**
         * @func gotoMap
         * @description as per type of the button or link this will take the user to the correct url
         */
        gotoMap: function(type){
            if(type == 'walk'){
                window.location.href = `./map-view.html?stamp=${+new Date}&type=walk&startLat=${this.startWalkPosition.lat}&startLong=${this.startWalkPosition.long}&init=1`
            }
            if(type == 'info'){
                alert('info clicked');
            }
            if(type == 'journey'){
                window.location.href = './select-journey.html'
            }
        },
        /**
         * @func gotoMapByUrl
         * @description as per type of the button or link this will take the user to the correct url
         */
        gotoMapByUrl(type, url){
            if(type == 'journey' && url != undefined){
                window.location.href = url;
            }
        }
    }
});

